import React from 'react';
import videos from "../Assets/videos/abcd.mp4";
import Env from '../Env';

function Banner() {
  return (
    <div className="container-fluid position-relative" style={{ overflow: 'hidden' }}>
      {/* Video Background */}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh', // Fullscreen height
          zIndex: -1,
          overflow: 'hidden'
        }}
      >
        <video
          src={videos}
          autoPlay
          loop
          muted
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover' // Ensures the video covers the whole area
          }}
        />
      </div>

      {/* Content */}
      <div className="d-flex flex-column justify-content-center align-items-center text-center text-white position-relative" style={{ minHeight: '100vh' }}>
        <h3>Welcome To</h3>
        <h1>{Env.businessName}</h1>
        <div className="text-center text-wrap">
          <h6>
            Morbi tristique senectus et netus et malesuada fames ac. <br />
            Nunc vel risus commodo viverra. Non pulvinar neque laoreet.
          </h6>
        </div>

        {/* Buttons */}
        <div className="d-flex justify-content-center align-items-center mt-4">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="btn text-white me-2"
              style={{
                background: 'linear-gradient(to top right, #EC2F4B, #009FFF)',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              LEARN MORE
            </button>
            <button className="btn bg-transparent border-0 d-flex align-items-center">
              <i className="bi bi-play-circle me-2" style={{ fontSize: '40px' }}></i>
              Watch Video
            </button>
          </div>
        </div>

        {/* Stats Section */}
        <div className="container-fluid mt-5">
          <div className="row justify-content-center mx-1">
            {['Vapesters', 'Happy Customers', 'Pre-Made Flavors', 'Gift Collections'].map((item, index) => (
              <div
                key={index}
                className={`col-md-2 my-3 ${index === 0 ? 'rounded-start' : ''} ${
                  index === 3 ? 'rounded-end' : ''
                }`}
                style={{
                  height: '130px',
                  backgroundColor: '#212529',
                  position: 'relative',
                }}
              >
                {index !== 0 && (
                  <div
                    style={{
                      height: '50%',
                      width: '1px',
                      backgroundColor: 'white',
                      position: 'absolute',
                      left: 0,
                      top: '25%',
                    }}
                  ></div>
                )}
                <i
                  className="bi bi-plus"
                  style={{
                    position: 'absolute',
                    top: '6px',
                    left: '74%',
                    transform: 'translateX(-50%)',
                    fontSize: '40px',
                    color: 'red',
                    textShadow: '2px 2px 4px black',
                  }}
                ></i>
                <div className="fw-bold fs-1 text-white mt-3">500</div>
                <div className="text-white">{item}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
