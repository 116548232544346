import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Navbar from './component/Navbar';
import Footer from './component/Footer';
import Filter from './component/Filterpage';
import ProductDescrtion from './component/Productdescrption';
import ViewCard from './component/Viewcard';
import Checkout from './component/Checkout';
function App() {
  return (
    <BrowserRouter>
   <Navbar/>



    <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="Filter" element={<Filter />}/>
      <Route path="ProductDescrtion" element={<ProductDescrtion />}/>
      <Route path="ViewCard" element={<ViewCard />}/>
      <Route path="Checkout" element={<Checkout />}/>
    </Routes>
    <Footer/>
  </BrowserRouter>
  );
}

export default App;
