const Env = { 
    // server: "http://localhost:8089", 
    // dashboard: "http://localhost:3001/",  

    Time:"Mon-Fri: 11am - 9pm",
    Email:"info@vapeshoprishton",
    facebook:"https://www.facebook.com/thevapeshoprishton",
    twitter:"https://twitter.com",
    instagram:"https://www.instagram.com/thevapeshop_rishton",
    youtube:"https://www.youtube.com",
    tiktok:"https://www.tiktok.com",
    logo:"https://ik.imagekit.io/tdyftai86/Black_and_Red__Minimalist_Bull_Est_Logo-removebg-preview.png?updatedAt=1732562983546",
    businessName:"The Vape Garage",
    PhNum:"+44 7387 504903",
    address:"10 High street bb1 4la Rishton"
    } 
export default Env;