import React from "react";
import Banner from "../component/Banner";
import Product from "../component/Product";
import WhoWeAre from "../component/WhoWeAre";
import Benifits from "../component/Benifits";
import Categories from "../component/Categories";
import BestServices from "../component/BestServices";
import Testimonial from "../component/Testimonial";

function Home() {
  return (
    <>
      <Banner />
      <Product />
      <WhoWeAre />
      <Benifits />
      <Categories />
      <BestServices />
      <Testimonial />
    </>
  );
}

export default Home;
