import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
const Filter = () => {
  const [colorDropdownVisible, setColorDropdownVisible] = useState(true);
  const [colorDropdown, setColorDropdown] = useState(true);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedproduct, setselectedproduct] = useState([]);

  return (
    <>
      <div id="carouselExampleSlidesOnly" class="carousel slide " data-bs-ride="carousel" data-bs-interval="3000">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-block w-100" src="https://www.vaporbeast.com/media/wysiwyg/blackfriday/category/delta/slimslider.jpg" alt="First slide" />
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="https://www.vaporbeast.com/media/wysiwyg/blackfriday/category/smokeshop/slimslider.jpg" alt="Second slide" />
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="https://www.vaporbeast.com/media/wysiwyg/blackfriday/category/eliquids/slimslider.jpg" alt="Third slide" />
          </div>
        </div>
      </div>
{/* -----------------------------Filter section------------------- */}
      <div className="container-fluid">
        <h1> Filter</h1> 
        <div className="row">
          <div className="col-md-2 border-light rounded border-silver">
            <div className="col  p-2">
              <label
                className="form-label fw-bold mb-2 d-flex justify-content-between align-items-center w-100"
                onClick={() => setColorDropdown((prev) => !prev)}
                style={{ cursor: 'pointer' }}
              >
                <span>Flavor Profile</span>
                <i className={`bi ${colorDropdown ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
              </label>
              {colorDropdownVisible && (
                <div className="mt-2">
                  {['Menthol', 'Mint', 'other flavors','Unflavored','N/A'].map((color) => (
                    <div className="form-check" key={color}>
                      <input
                        type="checkbox"
                        id={color}
                        className="form-check-input"
                        checked={selectedColors.includes(color)}
                        onChange={() => {
                          // Update selectedColors directly here
                          setSelectedColors((prev) =>
                            prev.includes(color) ? prev.filter((c) => c !== color) : [...prev, color]
                          );
                        }}
                      />
                      <label htmlFor={color} className="form-check-label">
                        {color}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <hr />
            <div className="col  p-2">
              <label
                className="form-label d-flex justify-content-between align-items-center"
                onClick={() => setColorDropdown((prev) => !prev)}
                style={{ cursor: 'pointer' }}
              >
                <span>Nicotine Experience</span>
                <i className={`bi ${colorDropdown ? 'bi-chevron-up' : 'bi-chevron-down'}`}></i>
              </label>

              {colorDropdown && (
                <div className="mt-2">
                  {['Freebase', 'Salt', 'Synthetic Freebase','Synthetic Salt'].map((color) => (
                    <div className="form-check" key={color}>
                      <input
                        type="checkbox"
                        id={color}
                        className="form-check-input"
                        checked={selectedproduct.includes(color)}
                        onChange={() => {
                          setselectedproduct((prev) =>
                            prev.includes(color) ? prev.filter((c) => c !== color) : [...prev, color]
                          );// Close dropdown after selection
                        }}
                      />
                      <label htmlFor={color} className="form-check-label">
                        {color}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div><hr />

            <img 
  src="https://www.vaporbeast.com/media/wysiwyg/brands/freezer/catside/freezer_oem_sidebanner.jpg" 
  className="img-fluid rounded " 
  alt="Vape Product" 
/>
<img 
  src="https://www.vaporbeast.com/media/wysiwyg/brands/roor/02-19-2024_VB_Roor_SideBanner.jpg" 
  className="img-fluid rounded mt-4" 
  alt="Vape Product" 
/>

          </div>


{/* -------------------------------------------------Bannaer image------------------------- */}
          <div className="col-12 col-md-10">
            <h1>Keep it 100</h1>
            <img
              src='https://www.vaporbeast.com/media/wysiwyg/brands/keepit100/catfull/KeepIt100_CategoryFull_Desktop.gif'
              alt="Keep It 100"
              className="img-fluid" 

            />

  {/* -------------------------------------Card product------------------- */}
            <div className="container mt-5">
              <div className="row justify-content-center">
                {[...Array(8)].map((_, index) => ( // Change 8 to the number of cards you want
                  <div key={index} className="col-md-3 d-flex justify-content-center mb-4">

                    <div className="card shadow-sm" style={{ width: "18rem", position: "relative", textAlign: "center" }}>
                      <img
                        src="https://www.vaporbeast.com/media/catalog/product/cache/55e0064ca1830c129586854b223940dc/k/e/keep-it-100-synth-pink.jpg"
                        className="card-img-top mx-auto mt-3"
                        alt="Vape Product"
                        style={{
                          width: "80%",
                          height: "auto",
                          objectFit: "contain",
                          transition: "transform 0.3s ease",
                        }}
                        onMouseOver={(e) => e.currentTarget.style.transform = "scale(1.1)"}
                        onMouseOut={(e) => e.currentTarget.style.transform = "scale(1)"}
                      />
                      <div className="card-body">
                        <h5 className="mb-0 fs-5 fw-bold">Premium Vape Device</h5>
                        <p className="card-text fs-4 mt-2">£49.99</p>
                        <Link to="/ProductDescrtion" style={{ textDecoration: 'none',color:'white' }}>
                        <button
                          className="btn text-white fs-5 fw-bold w-100 d-flex align-items-center justify-content-center"
                          style={{
                            background: "linear-gradient(to top right, #EC2F4B, #009FFF)",
                          }}>
                          <i className="fas fa-shopping-cart me-2"></i>
                          <i className="bi bi-handbag"></i>&nbsp;&nbsp; Add to Cart
                        </button>
                        </Link> 
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>




          </div>




        </div>
        <nav aria-label="Page navigation example ">
        <ul className="pagination justify-content-center ">
  <li className="page-item">
    <a className="page-link" href="#" aria-label="Previous" style={{ color: 'black' }}>
      <span aria-hidden="true">&laquo;</span>
    </a>
  </li>
  <li className="page-item">
    <a className="page-link" href="#" style={{ color: 'black' }}>1</a>
  </li>
  <li className="page-item">
    <a className="page-link" href="#" style={{ color: 'black' }}>2</a>
  </li>
  <li className="page-item">
    <a className="page-link" href="#" style={{ color: 'black' }}>3</a>
  </li>
  <li className="page-item">
    <a className="page-link" href="#" aria-label="Next" style={{ color: 'black' }}>
      <span aria-hidden="true">&raquo;</span>
    </a>
  </li>
</ul>

</nav>

      </div>




    </>
  )
}
export default Filter;