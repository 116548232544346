import React from 'react'

function WhoWeAre() {
  return (
    <div style={{background:"#000000" , color:"#FFFF"}}>
    <div className='container'>
      <div className='mt-5' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ flex: 1, padding: '30px', paddingLeft: "1%" }}>
          <h6>Who We Are.</h6>
          <h1>WE ARE THE BEST<br />
            VAPE STORE IN TOWN</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do<br />

            eiusmod tempor incididunt ut labore et dolore magna aliqua... </p>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
              <h5 style={{ marginRight: '10px', color: 'white', }}>Follow us:</h5>
              <a
                href="https://www.facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: '0 5px' }}
              >
                <i
                  className="bi bi-facebook"
                  style={{
                    fontSize: '20px',
                    color: 'white',
                    backgroundColor: '#212529',
                    borderRadius: '50%',
                    padding: '5px',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                ></i>
              </a>

              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" style={{ margin: '0 5px' }}>
                <i className="bi bi-instagram" style={{
                  fontSize: '20px',
                  color: 'white',
                  backgroundColor: '#212529',
                  borderRadius: '50%',
                  padding: '5px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }} ></i>
              </a>
              <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer" style={{ margin: '0 5px' }}>
                <i className="bi bi-tiktok" style={{
                  fontSize: '20px',
                  color: 'white',
                  backgroundColor: '#212529',
                  borderRadius: '50%',
                  padding: '5px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }} ></i>
              </a>
              <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" style={{ margin: '0 5px' }}>
                <i className="bi bi-youtube" style={{
                  fontSize: '20px',
                  color: 'white',
                  backgroundColor: '#212529',
                  borderRadius: '50%',
                  padding: '5px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }} ></i>
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" style={{ margin: '0 5px' }}>
                <i className="bi bi-twitter" style={{
                  fontSize: '20px',
                  color: 'white',
                  backgroundColor: '#212529',
                  borderRadius: '50%',
                  padding: '5px',
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }} ></i>
              </a>
            </div>
            <button
              type="button"
              className="btn text-white me-2"
              style={{
                background: 'linear-gradient(to top right, #EC2F4B, #009FFF)',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              About us <i className="bi bi-arrow-right-short"></i>
            </button>

          </div>
        </div>
        <div style={{ flex: 1, display: 'flex', padding: '5px', gap: '4px', alignItems: 'flex-start' }}>
          <img
            src="https://wallpaperbat.com/img/546881-vape.jpg"
            alt="Image 1"
            style={{
              maxWidth: '50%',
              borderRadius: '8px',
              marginTop: '45px' // Adds margin to the top of this image
            }}
          />
          <img
            src="https://st4.depositphotos.com/13778300/22014/i/450/depositphotos_220149854-stock-photo-hand-of-man-soars-on.jpg"
            alt="Image 2"
            style={{
              maxWidth: '50%',
              borderRadius: '8px'
            }}
          />

        </div>


      </div>

    </div>
</div>

  )
}

export default WhoWeAre