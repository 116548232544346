import React from 'react'

function Testimonial() {
  return (
    <div>
    <div className='container '>
    <div
    className="container d-flex align-items-center justify-content-center"
    style={{
      height: '20vh', // Full height of the viewport
      textAlign: 'center', // Center text horizontally
    }}
  >
    <div className="pt-5">
      <h5>Testimonial</h5>
      <p className="fw-bolder fs-2">WHAT OUR CUSTOMERS SAY</p>
    </div>

  </div>
  <div class="row  mt-4" style={{marginLeft:"10%"}}>
        <div class="col-md-6">
        <div class="d-flex align-items-center">
        <h6 class="mb-0 me-2">Best Product Ever!</h6>
        <div class="rating">
            <i class="bi bi-star-fill text-warning"></i>
            <i class="bi bi-star-fill text-warning"></i>
            <i class="bi bi-star-fill text-warning"></i>
            <i class="bi bi-star-fill text-warning"></i>
            <i class="bi bi-star-fill text-warning"></i>
        </div>
    </div>
            <p>"I will recommend you to my colleagues. Buy this now.<br/>
Thanks to vape, we've just launched our 5th website! I will<br/>
recommend you to my colleagues."</p>

<div class="d-flex align-items-center">
        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSwRPWpO-12m19irKlg8znjldmcZs5PO97B6A&s" style={{width: "10%"}} alt="Profile Image" class="img-fluid rounded-circle me-3" />
        <div>
            <h6 class="mb-0">Clarine Maroshk</h6>
            <p class="mb-0">Customer</p>
        </div>
    </div>


        </div>
        <div class="col-md-6">
        <div class="d-flex align-items-center">
        <h6 class="mb-0 me-2">Best Product Ever!</h6>
        <div class="rating">
            <i class="bi bi-star-fill text-warning"></i>
            <i class="bi bi-star-fill text-warning"></i>
            <i class="bi bi-star-fill text-warning"></i>
            <i class="bi bi-star-fill text-warning"></i>
            <i class="bi bi-star-fill text-warning"></i>
        </div>
    </div>
            <p>"I will recommend you to my colleagues. Buy this now.<br/>
Thanks to vape, we've just launched our 5th website! I will<br/>
recommend you to my colleagues."</p>

<div class="d-flex align-items-center">
        <img src="https://cdn.pixabay.com/photo/2021/04/27/17/47/woman-6212065_640.jpg" style={{width: "10%"}} alt="Profile Image" class="img-fluid rounded-circle me-3" />
        <div>
            <h6 class="mb-0">Clarine Maroshk</h6>
            <p class="mb-0">Customer</p>
        </div>
    </div>


        </div>
    </div>
    <div className="row mt-4 mb-3 mx-5" > 
    <div className="col-12 col-lg-3 col-md-3  align-items-center mt-5" style={{ marginLeft: "7%", margin: 0, padding: 0 }}>
    <h5 className="" style={{ 
    marginLeft: "10%", 
    backgroundColor: "#212529", 
    color: "white", 
    padding: "20px", 
    borderRadius: "8px"  // Adjust the value as needed for roundness
}}>
    Trusted by our  partners
</h5>
</div>
<div className='col-12 col-lg-9 col-md-9'>
         
    </div>
   
</div>



    </div>
    <div className="col-12 position-relative " >
  <img 
    src="https://ik.imagekit.io/tdyftai86/Untitled%20design%20(3).png?updatedAt=1731307082592" 
    style={{ width: "100%", height: "250px", objectFit: "cover" }} 
    alt="Example" 
  />
<div className='container'>
  {/* Content in the center */}
  <div className='row'>
  <div className='col-12 col-md-8 col-lg-8 d-none d-lg-block d-md-block'>
  <div
   style={{
    position: "absolute", 
    top: "50%", 
    left: "50%", 
    transform: "translate(-180%, -50%)", 
    color: "white",  
    display: "flex", 
    flexDirection: "column", 
    gap: "10px"
  }}>
    <h6 style={{ margin: 0,color:"#EC2F4B" }}>Our Newsletter</h6>
    <h4 style={{ margin: 0 }}>GET THE UPDATED NEWS</h4>
  </div>
  </div>
  {/* Input field and button on the right */}
  <div className='col-12 col-md-4 col-lg-4'>
  <div style={{ 
    position: "absolute", 
    top: "50%", 
    right: "5%", 
    transform: "translateY(-50%)", 
    display: "flex" // Flex container ensures side-by-side alignment 
  }}>
  
  <input
    type="text"
    placeholder="Enter the email"
    style={{
      width: "300px",
      padding: "10px",
      border: "1px solid #ccc",
      borderRadius: "5px 5px 5px 5px", // Rounded on the left
      outline: "none"
    }}
  />
<button
    style={{
      position: "absolute",
      right: "10px",
      top: "50%",
      transform: "translateY(-50%)",
      border: "none",
      background: 'linear-gradient(to top, #EC2F4B, #009FFF)', // Fixed gradient syntax
      color: "#FFFFFF",
      borderRadius: "5px",
      padding: "8px 12px",
      cursor: "pointer"
    }}
>
   Subscribe
</button>

 

</div>
</div>
</div>
  </div>
</div>

  </div>
  )
}

export default Testimonial