import React from "react";
import { Link } from 'react-router-dom';
const ProductDescrtion = () => {
    return (
        <>
            <div className="container-fluid px-3">
                <img src="https://www.vaporbeast.com/media/wysiwyg/products/juicehead/pouches/199/slimslider/slimslider.jpg" className="rounded img-fluid" alt="Rounded Image" />
            </div>
            <div class="container my-3">
                <div class="row">
                    <div class="col-md-5">
                        <div id="productSlider" class="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">

                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img src="https://www.vaporbeast.com/media/catalog/product/cache/991a04838e27c0fbaf408f28add1f515/i/c/icon_keep-it-100-synth-unflavored-30-ml.jpg" class="d-block w-100" alt="ProductS" />
                                </div>
                                <div class="carousel-item">
                                    <img src="https://www.vaporbeast.com/media/catalog/product/cache/991a04838e27c0fbaf408f28add1f515/i/c/icon_keep-it-100-synth-menthol-30-ml.jpg" class="d-block w-100" alt="Sale" />
                                </div>
                                <div class="carousel-item">
                                    <img src="https://www.vaporbeast.com/media/catalog/product/cache/991a04838e27c0fbaf408f28add1f515/i/c/icon_keep-it-100-synth-mint-30-ml.jpg" class="d-block w-100" alt="Sale" />
                                </div>
                                <div class="carousel-item">
                                    <img src="https://www.vaporbeast.com/media/catalog/product/cache/55e0064ca1830c129586854b223940dc/i/c/icon_keep-it-100-synth-unflavored-30-ml.jpg" class="d-block w-100" alt="Sale" />
                                </div>
                            </div>

                            <button class="carousel-control-prev bg-dark  text-white" type="button" data-bs-target="#productSlider" data-bs-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Previous</span>
                            </button>
                            <button class="carousel-control-next bg-dark   text-white" type="button" data-bs-target="#productSlider" data-bs-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="visually-hidden">Next</span>
                            </button>

                        </div>

                        <div class="row my-3 previews">
                            <div class="col-md-3">
                                <img class="w-100" src="https://www.vaporbeast.com/media/catalog/product/cache/991a04838e27c0fbaf408f28add1f515/i/c/icon_keep-it-100-synth-menthol-30-ml.jpg" alt="Sale" />
                            </div>
                            <div class="col-md-3">
                                <img class="w-100" src="https://www.vaporbeast.com/media/catalog/product/cache/991a04838e27c0fbaf408f28add1f515/i/c/icon_keep-it-100-synth-mint-30-ml.jpg" alt="Sale" />
                            </div>
                            <div class="col-md-3">
                                <img class="w-100" src="https://www.vaporbeast.com/media/catalog/product/cache/991a04838e27c0fbaf408f28add1f515/i/c/icon_keep-it-100-synth-menthol-30-ml.jpg" alt="Sale" />
                            </div>
                            <div class="col-md-3">
                                <img class="w-100" src="https://www.vaporbeast.com/media/catalog/product/cache/55e0064ca1830c129586854b223940dc/i/c/icon_keep-it-100-synth-unflavored-30-ml.jpg" alt="Sale" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-7 border rounded">
                        <div class="main-description px-2 mt-3 ">
                            <div class="category fw-bolder fs-2 text-bold">
                                Keep It 100 Synth Salts Mint
                            </div><hr />
                            <div class="product-details my-4">
                                <p class="details-title text-color mb-1">Product Details</p>
                                <p class="description">Keep It 100 Salts Mint serves up a crisp and refreshing mint flavor, striking the ideal balance of coolness in every puff for a smooth, invigorating vape perfect for all-day satisfaction. </p>
                            </div>
                            <div class="product-title text-bold my-3">
                                <span className="fw-bold fs-5">Brand:</span>  Keep it 100
                                | Keep it 100 Quick Order Form
                            </div>


                            <div class="price-area my-4">
                                <p class="old-price mb-1 fs-5"><del>£100</del> <span class="old-price-discount text-danger">(20% off)</span></p>
                                <p class="new-price text-bold mb-1 fs-3 fw-bold">£80</p>
                                <p class="text-secondary mb-1">(Additional tax may apply on checkout)</p>

                            </div>


                            <div class="buttons d-flex my-5">
                                <div class="block ">
                                    <a href="#" class="shadow  btn custom-btn border  fs-4 rounded text-white"> <i class="bi bi-suit-heart"></i> &nbsp;Wishlist</a>
                                </div>
                                <div class="block mx-2">
                                    <button class="shadow btn custom-btn fs-4 border text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">  <i className="bi bi-handbag"></i>&nbsp; Add to cart</button>
                                </div>

                                <div class="block quantity ">
                                    <input type="number" class="form-control px-1 mt-1 py-2" id="cart_quantity" value="1" min="0" max="5" placeholder="Enter email" name="cart_quantity" />
                                </div>
                            </div>




                        </div>



                        <div class="row questions bg-light p-3">
                            <div class="col-md-1 icon">
                                <i class="fa-brands fa-rocketchat questions-icon"></i>
                            </div>
                            <div class="col-md-11 " style={{ color: "black" }}>
                                Warning for California Residents Regarding Proposition 65: This product contains nicotine, a chemical known to the State of California to cause birth defects or other reproductive harm.
                            </div>
                        </div>

                        <div class="delivery my-4">
                            <p class="font-weight-bold mb-0"><span><i class="fa-solid fa-truck"></i></span> <b>Delivery done in 3 days from date of purchase</b> </p>
                            <p class="text-secondary">Order now to get this product delivery</p>
                        </div>
                        {/* <div class="delivery-options my-4">
                    <p class="font-weight-bold mb-0"><span><i class="fa-solid fa-filter"></i></span> <b>Delivery options</b> </p>
                    <p class="text-secondary">View delivery options here</p>
                </div> */}


                    </div>
                </div>
            </div>


            {/* ----------------------------------similar product--------------------------- */}
            <div class="container similar-products my-4">
                <hr />
                <div class=" position-relative mb-4 ">
                    <p class="display-5 d-inline-block fw-bolder">Similar Products</p>
                    <div class="position-absolute start-50 translate-middle-x mt-1" style={{ width: '50%', height: '2px', background: '#000' }}></div>
                </div>


                <div class="row">
                    <div class="col-md-3">
                        <div className="position-relative">
                            <img
                                className="w-100 rounded"
                                src="https://www.vaporbeast.com/media/catalog/product/cache/55e0064ca1830c129586854b223940dc/i/c/icon_keep-it-100-x-red_s-salts-slapple-iced.jpg"
                                alt="Preview"
                            />
                            <p
                                className="position-absolute top-0 bg-blue border end-0 w-25 fs-3 fw-bold m-0 p-1"
                                style={{
                                    borderRadius: "5px",
                                    backgroundColor: "rgba(0, 0, 5, 0.5)",
                                    textAlign: "center",
                                }}
                            >
                                $100
                            </p>
                            <p
                                className="position-absolute bottom-0 start-50 translate-middle-x bg-blue border w-100  fs-5 fw-bold m-0 p-1 text-center text-white"
                                style={{
                                    backgroundColor: "rgba(0, 0, 5, 0.8)", // Semi-transparent blue background
                                    borderRadius: "5px",
                                }}
                            >
                                Keep it 100 Synth Mint
                            </p>


                        </div>

                        {/* Product Name Text at the bottom */}

                        {/* Add to Cart Button */}


                    </div>
                    <div class="col-md-3">
                        <div className="position-relative">
                            <img
                                className="w-100 rounded"
                                src="https://www.vaporbeast.com/media/catalog/product/cache/55e0064ca1830c129586854b223940dc/k/e/keep-it-100-synth-pink.jpg"
                                alt="Preview"
                            />
                            <p
                                className="position-absolute top-0 bg-blue border end-0 w-25 fs-3 fw-bold m-0 p-1"
                                style={{
                                    borderRadius: "5px",
                                    backgroundColor: "rgba(0, 0, 5, 0.5)",
                                    textAlign: "center",
                                }}
                            >
                                £50
                            </p>
                            <p
                                className="position-absolute bottom-0 start-50 translate-middle-x bg-blue border w-100  fs-5 fw-bold m-0 p-1 text-center text-white"
                                style={{
                                    backgroundColor: "rgba(0, 0, 5, 0.8)", // Semi-transparent blue background
                                    borderRadius: "5px",
                                }}
                            >
                                Keep It 100 Synthetic Pink
                            </p>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div className="position-relative">
                            <img
                                className="w-100 rounded"
                                src="https://www.vaporbeast.com/media/catalog/product/cache/55e0064ca1830c129586854b223940dc/k/e/keep-it-100-synth-b-a-l.jpg"
                                alt="Preview"
                            />
                            <p
                                className="position-absolute top-0 bg-blue border end-0 w-25 fs-3 fw-bold m-0 p-1"
                                style={{
                                    borderRadius: "5px",
                                    backgroundColor: "rgba(0, 0, 5, 0.5)",
                                    textAlign: "center",
                                }}
                            >
                                £80
                            </p>
                            <p
                                className="position-absolute bottom-0 start-50 translate-middle-x bg-blue border w-100  fs-5 fw-bold m-0 p-1 text-center text-white"
                                style={{
                                    backgroundColor: "rgba(0, 0, 5, 0.8)", // Semi-transparent blue background
                                    borderRadius: "5px",
                                }}
                            >
                                Keep It 100 Synthetic B.A.L.
                            </p>


                        </div>

                        {/* Product Name Text at the bottom */}

                        {/* Add to Cart Button */}


                    </div>
                    <div class="col-md-3">
                        <div className="position-relative">
                            <img
                                className="w-100 rounded"
                                src="https://www.vaporbeast.com/media/catalog/product/cache/55e0064ca1830c129586854b223940dc/i/c/icon_keep-it-100-x-red_s-salts-slapple.jpg"
                                alt="Preview"
                            />
                            <p
                                className="position-absolute top-0 bg-blue border end-0 w-25 fs-3 fw-bold m-0 p-1"
                                style={{
                                    borderRadius: "5px",
                                    backgroundColor: "rgba(0, 0, 5, 0.5)",
                                    textAlign: "center",
                                }}
                            >
                                £50
                            </p>
                            <p
                                className="position-absolute bottom-0 start-50 translate-middle-x bg-blue border w-100  fs-5 fw-bold m-0 p-1 text-center text-white"
                                style={{
                                    backgroundColor: "rgba(0, 0, 5, 0.8)", // Semi-transparent blue background
                                    borderRadius: "5px",
                                }}
                            >
                                Keep It 100 x Red's Salts
                            </p>


                        </div>

                        {/* Product Name Text at the bottom */}

                        {/* Add to Cart Button */}


                    </div>
                </div>
            </div>
{/* ---------------------------Modal show----------------------- */}
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
      <div class="modal-body d-flex  rounded justify-content-between " style={{background:'#f91f2d'}}>
  <h5 class="modal-title text-white " id="exampleModalLabel">Subtotal: £74.95</h5>
  <h6 class="modal-title text-white " id="exampleModalLabel">3 item add</h6>
</div>

       
      </div>
      <div class="modal-body">
     <div className="row">
     <div className="col-md-6">
      <img
        src="https://www.vaporbeast.com/media/catalog/product/cache/55e0064ca1830c129586854b223940dc/i/c/icon_keep-it-100-x-red_s-salts-slapple.jpg"
        alt="Product"
        style={{ width: '100px', height: 'auto' }} // Set your desired width and height
      />
    </div>
        <div className="col-md-6 text-dark">
            <p>Juice Head Strawberry Kiwi &nbsp;<i class="bi bi-x-lg  text-danger "></i>
            </p>
            
            <div className="d-flex  my-3 rounded justify-content-between">
            <h5>£14.99</h5> 
            <p>
  Qty: <span className="border rounded text-dark me-5 px-3 py-1">5</span>
</p>

            </div>
        </div>
     </div>
     <div className="row " style={{borderTop:'1px solid #d7dadd'}}>
     <div className="col-md-6 pt-3">
      <img
        src="https://www.vaporbeast.com/media/catalog/product/cache/55e0064ca1830c129586854b223940dc/i/c/icon_keep-it-100-x-red_s-salts-slapple-iced.jpg"
        alt="Product"
        style={{ width: '100px', height: 'auto' }} // Set your desired width and height
      />
    </div>
        <div className="col-md-6 text-dark pt-3">
            <p>Keep It 100 Synth Menthol &nbsp;<i class="bi bi-x-lg  text-danger "></i>
            </p>
            
            <div className="d-flex  my-3 rounded justify-content-between">
            <h5>£14.99</h5> 
            <p>
  Qty: <span className="border rounded text-dark me-5 px-3 py-1">5</span>
</p>

            </div>
        </div>
     </div>
      </div>
      
      <div class="modal-footer d-flex justify-content-center w-100 ">
      <Link to="/ViewCard" style={{ textDecoration: 'none',color:'white' }}>
  <button type="button" class="btn px-5  fw-bold" data-bs-dismiss="modal" style={{background:'#000000',color:'white'}}>VIEW CARD</button>
  </Link>
  <Link to="/Checkout" style={{ textDecoration: 'none',color:'white' }}>
  <button type="button" class="btn px-5 fw-bold " style={{background:'#f91f2d',color:'white  '}}>CHECKOUT</button>
  </Link>
</div>

    </div>
  </div>
</div>



        </>
    )
}
export default ProductDescrtion;