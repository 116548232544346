import React from 'react'

function Categories() {
  return (
    <div className='container mt-5'>
      <h5 className='text-danger'>Categories</h5>
      <div class="d-flex align-items-center">
      <h1 class="me-auto text-nowrap">PRODUCT SELECTION</h1>
        <button
  type="button"
  className="btn text-black btn-sm text-nowrap d-none d-md-block d-lg-block" // Show only on small screens
  style={{
    background: 'white',
    border: '2px solid black',
    borderRadius: '5px',
    padding: '10px 20px',
    cursor: 'pointer',
  }}
>
  More products
</button>




      </div>


      <div class="container mt-4">
        <div class="row no-gutters text-center">
          <div class="col-12 col-md-4 col-lg-4 mb-0 bg-dark text-white d-flex flex-column align-items-center justify-content-center" >
            <h6>E-Juices</h6>
            <p style={{ textAlign: "left" }}>
              Lorem ipsum dolor sit amet,<br />
              co adipiscing elit, sed do<br />
              eiusmod tempor incididunt.
            </p>
            <button
              type="button"
              className="btn text-white me-2"
              style={{
                border: 'none',
                padding: '5px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
                float: 'left' // This will align the button to the left
              }}
            >
              EXPLORE <i class="bi bi-chevron-right"></i>
            </button>

          </div>


          <div class="col-12 col-md-4 col-lg-4 mb-0 p-0">
            <img src="https://www.creativefabrica.com/wp-content/uploads/2023/06/01/Vape-Eliquid-Bottle-Graphics-71067451-1-1-580x387.jpg"
              alt="Descriptive Alt Text"
              class="img-fluid " />
          </div>


          <div class="col-12 col-md-4 mb-0 bg-dark text-white d-flex flex-column align-items-center justify-content-center" >
            <h6>E-Juices</h6>
            <p style={{ textAlign: "left" }}>
              Lorem ipsum dolor sit amet,<br />
              co adipiscing elit, sed do<br />
              eiusmod tempor incididunt.
            </p>
            <button
              type="button"
              className="btn text-white me-2"
              style={{
                border: 'none',
                padding: '5px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
                float: 'left'
              }}
            >
              EXPLORE <i class="bi bi-chevron-right"></i>
            </button>

          </div>
        </div>
      </div>

      {/* ------second card----------- */}
      <div class="container ">
        <div class="row no-gutters text-center">
          <div class="col-12 col-md-4 mb-0 p-0">
            <img src="https://www.creativefabrica.com/wp-content/uploads/2023/06/01/Vape-Eliquid-Bottle-Graphics-71067451-1-1-580x387.jpg"
              alt="Descriptive Alt Text"
              class="img-fluid " />
          </div>


          <div class="col-12 col-md-4 mb-0 bg-dark text-white d-flex flex-column align-items-center justify-content-center" >
            <h6>E-Juices</h6>
            <p style={{ textAlign: "left" }}>
              Lorem ipsum dolor sit amet,<br />
              co adipiscing elit, sed do<br />
              eiusmod tempor incididunt.
            </p>
            <button
              type="button"
              className="btn text-white me-2"
              style={{
                border: 'none',
                padding: '5px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
                float: 'left' // This will align the button to the left
              }}
            >
              EXPLORE <i class="bi bi-chevron-right"></i>
            </button>

          </div>


          <div class="col-12 col-md-4 mb-0 p-0">
            <img src="https://www.creativefabrica.com/wp-content/uploads/2023/06/01/Vape-Eliquid-Bottle-Graphics-71067451-1-1-580x387.jpg"
              alt="Descriptive Alt Text"
              class="img-fluid " />
          </div>
        </div>
      </div>


      {/* -----------------------------How choose us--------------------- */}

      <div className='mt-5 ' >
        <div class="row ">
          <div className='col-12 col-lg-8 col-md-8' >
        <div style={{ flex: 1, padding: '30px', paddingLeft: "1%" }}>
        <h6 className="text-danger" style={{ whiteSpace: 'nowrap' }}>Who We Are.</h6>
          <h1 style={{ whiteSpace: 'nowrap' }} >WE ARE THE BEST<br />
            VAPE STORE IN TOWN</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do<br/>

            eiusmod tempor incididunt ut labore et dolore magna aliqua... </p>
          <div>
          </div>
        </div>
        </div>
        {/* -------------------------progressbare-------------- */}
        <div className='col-12 col-lg-3 col-md-3 '>
        <div style={{ flex: 1, gap: '4px', alignItems: 'flex-start' }}>
          <div style={{ position: 'relative',  margin: 'auto' }}>
            {/* Paragraph overlayed on top of the progress bar */}
            <p
              style={{
                position: 'absolute',
                left: "20%",
                paddingBottom: "5%",
                transform: 'translate(-50%, -50%)',
                margin: 0,
                color: 'white',
                fontWeight: 'bold',
                zIndex: 2,
              }}
            >
              BATTERY LIFE
            </p>
            <div
              className="progress mt-5"
              role="progressbar"
              aria-label="Example with label"
              aria-valuenow={25}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ width: '120%', height: '100%' }}

            >
              <div className="progress-bar" style={{ width: '90%', background: 'linear-gradient(to top, #e03554, #81629d, #258de3)', }}>
                90%
              </div>
            </div>
          </div>
          <div style={{ position: 'relative',  margin: 'auto' }}>
            <p
              style={{
                position: 'absolute',
                left: "25%",
                paddingBottom: "5%",
                transform: 'translate(-50%, -50%)',
                margin: 0,
                color: 'white',
                fontWeight: 'bold',
                zIndex: 2,
              }}
            >
              FIRING BUTTON
            </p>
            <div
              className="progress mt-4"
              role="progressbar"
              aria-label="Example with label"
              aria-valuenow={25}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ width: '120%', height: '100%' }}

            >
              <div className="progress-bar" style={{ width: '75%', background: 'linear-gradient(to top right, #EC2F4B, #009FFF)', }}>
                75%
              </div>
            </div>
          </div>

          <div style={{ position: 'relative', margin: 'auto' }}>
            <p
              style={{
                position: 'absolute',
                left: "27%",
                paddingBottom: "5%",
                transform: 'translate(-50%, -50%)',
                margin: 0,
                color: 'white',
                fontWeight: 'bold',
                zIndex: 2,
              }}
            >
              SAFETY FEATURES
            </p>
            <div
              className="progress mt-4"
              role="progressbar"
              aria-label="Example with label"
              aria-valuenow={25}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ width: '120%', height: '100%' }}

            >
              <div className="progress-bar " style={{ width: '92%', background: 'linear-gradient(to top, #e03554, #81629d, #258de3)' }}>
                92%
              </div>
            </div>
          </div>
          <div style={{ position: 'relative',  margin: 'auto' }}>
            <p
              style={{
                position: 'absolute',
                left: "27%",
                paddingBottom: "5%",
                transform: 'translate(-50%, -50%)',
                margin: 0,
                color: 'white',
                fontWeight: 'bold',
                zIndex: 2,
              }}
            >
              FLOW CONTROL
            </p>
            <div
              className="progress mt-4"
              role="progressbar"
              aria-label="Example with label"
              aria-valuenow={86}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{ width: '120%', height: '20px' }} // Adjust height as needed
            >
              <div
                className="progress-bar"
                style={{
                  width: '86%',
                  background: 'linear-gradient(to top, #e03554, #81629d, #258de3)', // Gradient color
                }}
              >
                86%
              </div>
            </div>
          </div>

        </div>
        </div>
</div>
      </div>
    </div>
  )
}

export default Categories