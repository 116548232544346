import React from 'react'

function Benifits() {
  return (
  <div className='container'>
    
{/* -----------------------------THe banefit------------------- */}
<div className=" mt-5 d-flex flex-column justify-content-center align-items-center"  >
        <h5 className="text-danger mt-5">The Benefits</h5>
        <h1>VAPING VS SMOKING</h1>
      </div>

      <div class="row" style={{paddingLeft: "5%"}}>
      <div class="col-12 col-sm-4 col-md-4 ">
      <div class="row">
        <div class="col-12 col-md-10 col-lg-10 mb-4"> 
            <div className='  rounded'  style={{background:"#212529", boxShadow:
      'inset 2px 2px 5px rgba(255, 255, 255, 0.1), 4px 4px 10px rgba(0, 0, 0, 0.6)',}}>
                <div class="d-flex ms-4 text-white">
                    <div class="p-2 rounded me-2"> 
                        <i class="bi bi-emoji-laughing p-2 fs-1 mt-4"
                        style={{
                            fontSize: '20px',
                            color: 'white',
                            backgroundColor: 'red',
                            borderRadius: '50%',
                            padding: '5px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}></i>
                    </div>
                    <div class="mt-4"> 
                        <h6>No Bad Smell</h6>
                        <p class="no-spacing">
                            Lorem ipsum dolor sit<br/>
                            amet, consectetur adipiscing.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-10 col-lg-10 mb-4">
            <div className='  rounded ' style={{background:"#212529",boxShadow:
      'inset 2px 2px 5px rgba(255, 255, 255, 0.1), 4px 4px 10px rgba(0, 0, 0, 0.6)',}}>
                <div class="d-flex ms-4 text-white">
                    <div class="p-2 rounded me-2"> 
                        <i class="bi bi-folder fs-1 p-2 mt-4"
                        style={{
                            fontSize: '20px',
                            color: 'white',
                            backgroundColor: 'red',
                            borderRadius: '50%',
                            padding: '5px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}></i>
                    </div>
                    <div class="mt-4"> 
                        <h6>More Cost Effective</h6>
                        <p class="no-spacing">
                            Lorem ipsum dolor sit<br/>
                            amet, consectetur adipiscing.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-10 col-lg-10 mb-4"> 
            <div className='  rounded' style={{background:"#212529",boxShadow:
      'inset 2px 2px 5px rgba(255, 255, 255, 0.1), 4px 4px 10px rgba(0, 0, 0, 0.6)',}}>
                <div class="d-flex ms-4 text-white">
                    <div class="p-2 rounded me-2"> 
                        <i class="bi bi-person-raised-hand fs-1 mt-4"
                        style={{
                            fontSize: '20px',
                            color: 'white',
                            backgroundColor: 'red',
                            borderRadius: '50%',
                            padding: '5px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}></i>
                    </div>
                    <div class="mt-4"> 
                        <h6>Help Quit Smoking</h6>
                        <p class="no-spacing">
                            Lorem ipsum dolor sit<br/>
                            amet, consectetur adipiscing.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="col-12 col-sm-3 col-md-3 col-lg-3 text-center " style={{ position: "relative",  }}>

  <img 
    src="https://ik.imagekit.io/tdyftai86/Screenshot_2024-11-08_225642-removebg-preview.png?updatedAt=1731088764465"
    alt="Placeholder Image"
    className="img-fluid"
    style={{ 
      position: "relative", 
      width: "200px", 
      height: "auto", 
     
    }}  
  />
</div>



        <div class="col-12 col-sm-4 col-md-4" style={{marginLeft:"4%" }}>
        <div class="row">
        <div class="col-12 col-md-10 col-lg-10 mb-4"> 
            <div className='  rounded' style={{background:"#212529",boxShadow:
      'inset 2px 2px 5px rgba(255, 255, 255, 0.1), 4px 4px 10px rgba(0, 0, 0, 0.6)',}}>
                <div class="d-flex ms-4 text-white">
                    <div class="p-2 rounded me-2"> 
                        <i class="bi bi-archive-fill p-2 fs-1 mt-4 "
                        style={{
                            fontSize: '20px',
                            color: 'white',
                            backgroundColor: '#EC2F4B',
                            borderRadius: '50%',
                            padding: '5px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}></i>
                    </div>
                    <div class="mt-4"> 
                        <h6>Safer than Smoking</h6>
                        <p class="no-spacing">
                            Lorem ipsum dolor sit<br/>
                            amet, consectetur adipiscing.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-10 col-lg-10 mb-4">
            <div className='  rounded' style={{background:"#212529",boxShadow:
      'inset 2px 2px 5px rgba(255, 255, 255, 0.1), 4px 4px 10px rgba(0, 0, 0, 0.6)',}}>
                <div class="d-flex ms-4 text-white">
                    <div class="p-2 rounded me-2"> 
                        <i class="bi bi-people-fill fs-1 p-2 mt-4"
                        style={{
                            fontSize: '20px',
                            color: 'white',
                            backgroundColor: 'red',
                            borderRadius: '50%',
                            padding: '5px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}></i>
                    </div>
                    <div class="mt-4"> 
                        <h6>Harmless to Around</h6>
                        <p class="no-spacing">
                            Lorem ipsum dolor sit<br/>
                            amet, consectetur adipiscing.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12 col-md-10 col-lg-10 mb-4"> 
            <div className='  rounded' style={{background:"#212529",boxShadow:
      'inset 2px 2px 5px rgba(255, 255, 255, 0.1), 4px 4px 10px rgba(0, 0, 0, 0.6)',}}>
                <div class="d-flex ms-4 text-white">
                    <div class="p-2 rounded me-2"> 
                        <i class="bi bi-person-wheelchair  p-2 fs-1 mt-4"
                        style={{
                            fontSize: '20px',
                            color: 'white',
                            backgroundColor: 'red',
                            borderRadius: '50%',
                            padding: '5px',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}></i>
                    </div>
                    <div class="mt-4"> 
                        <h6>Better For Your Health</h6>
                        <p class="no-spacing">
                            Lorem ipsum dolor sit<br/>
                            amet, consectetur adipiscing.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>
    </div>

  </div>
  )
}

export default Benifits