import React from "react";
import Env from "../Env";

function Footer() {
  return (
    <div>
      <footer className="text-center text-lg-start bg-body-dark text-white ">
        {/* Social Section */}
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom border-top">
          <div className="me-5 d-none d-lg-block fw-bold fs-4">
            <span>Get connected with us on social networks</span>
          </div>
          <div>
            <a
              href="#"
              className="me-4 text-reset bg-dark d-inline-flex align-items-center justify-content-center"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                color: "white",
                fontSize: "1.5rem",
              }}
            >
              <i className="bi bi-facebook" />
            </a>

            <a
              href="#"
              className="me-4 text-reset bg-dark d-inline-flex align-items-center justify-content-center"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                color: "white",
                fontSize: "1.5rem",
              }}
            >
              <i className="bi bi-twitter" />
            </a>
            <a
              href="#"
              className="me-4 text-reset bg-dark d-inline-flex align-items-center justify-content-center"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                color: "white",
                fontSize: "1.5rem",
              }}
            >
              <i className="bi bi-google" />
            </a>
            <a
              href="#"
              className="me-4 text-reset bg-dark d-inline-flex align-items-center justify-content-center"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                color: "white",
                fontSize: "1.5rem",
              }}
            >
              <i className="bi bi-instagram" />
            </a>
            <a
              href="#"
              className="me-4 text-reset bg-dark d-inline-flex align-items-center justify-content-center"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                color: "white",
                fontSize: "1.5rem",
              }}
            >
              <i className="bi bi-linkedin" />
            </a>
            <a
              href="#"
              className="me-4 text-reset bg-dark d-inline-flex align-items-center justify-content-center"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                color: "white",
                fontSize: "1.5rem",
              }}
            >
              <i className="bi bi-github" />
            </a>
          </div>
        </section>

        {/* Main Footer Content */}
        <section>
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              {/* Company Info */}
              <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <img
                    src={Env.logo}
                    alt={Env.businessName}
                    height={170}
                  />
                </h6>
                <p>
                  Here you can use rows and columns to organize your footer
                  content. Lorem ipsum dolor sit amet, consectetur adipisicing
                  elit.
                </p>
              </div>

              {/* Products */}
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Products</h6>
                <p>
                  <a
                    href="#!"
                    className="text-reset"
                    style={{ textDecoration: "none" }}
                  >
                    Angular
                  </a>
                </p>
                <p>
                  <a
                    href="#!"
                    className="text-reset"
                    style={{ textDecoration: "none" }}
                  >
                    React
                  </a>
                </p>
                <p>
                  <a
                    href="#!"
                    className="text-reset"
                    style={{ textDecoration: "none" }}
                  >
                    Vue
                  </a>
                </p>
                <p>
                  <a
                    href="#!"
                    className="text-reset"
                    style={{ textDecoration: "none" }}
                  >
                    Laravel
                  </a>
                </p>
              </div>

              {/* Useful Links */}
              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6
                  className="text-uppercase fw-bold mb-4"
                  style={{ textDecoration: "none" }}
                >
                  Useful links
                </h6>
                <p>
                  <a
                    href="#!"
                    className="text-reset"
                    style={{ textDecoration: "none" }}
                  >
                    Pricing
                  </a>
                </p>
                <p>
                  <a
                    href="#!"
                    className="text-reset"
                    style={{ textDecoration: "none" }}
                  >
                    Settings
                  </a>
                </p>
                <p>
                  <a
                    href="#!"
                    className="text-reset"
                    style={{ textDecoration: "none" }}
                  >
                    Orders
                  </a>
                </p>
                <p>
                  <a
                    href="#!"
                    className="text-reset"
                    style={{ textDecoration: "none" }}
                  >
                    Help
                  </a>
                </p>
              </div>

              {/* Contact Info */}
              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <i className="bi bi-house me-3" /> {Env.address}
                </p>
                <p>
                  <i className="bi bi-envelope me-3" /> {Env.Email}
                </p>
                <p>
                  <i className="bi bi-telephone me-3" /> {Env.PhNum}
                </p>
                <p>
                  <i className="bi bi-printer me-3" /> {Env.PhNum}
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Copyright Section */}
        <div
          className="text-center container-fluid p-4"
          style={{
            // Full height of the viewport
            // Full width of the viewport
            background:
              "linear-gradient(145deg, rgba(0,0,0,0.8), rgba(30,30,30,1))",
            boxShadow:
              "inset 2px 2px 5px rgba(255, 255, 255, 0.1), 4px 4px 10px rgba(0, 0, 0, 0.6)",
            margin: 0, // Remove margins to avoid scrollbars
            padding: 0, // Optional: Control padding as needed
            color: "white",
            display: "flex", // Align content vertically and horizontally
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
            textAlign: "center",
          }}
        >
          <div>
            © 2024 Copyright - &nbsp;
            <a
              className="text-reset fw-bold"
              style={{ textDecoration: "none" }}
              href="https://mdbootstrap.com/"
            >
              Webtime Solution LTD
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
