import React from 'react'
import { Link } from 'react-router-dom';
function Product() {
  return (
    <div style={{background:"#000000" , color:"#FFFF"}}>
    <div className="container "  >
      <div className="d-flex flex-column justify-content-center align-items-center" >
        <h5 className="text-danger mt-5">Products</h5>
        <h1>Explore Latest Vapes</h1>

      </div>

      <div className="row mt-4">
        {/* Card 1 */}
        <div className="col-12 col-md-3 mb-4">
        <Link to="/Filter" style={{ textDecoration: 'none',color:'white' }}>
      <div className="card " style={{ border: '2px solid red', borderRadius: '30px' }}>
        <img
          src="https://www.vaporbeast.com/media/wysiwyg/beast/catgrid/CategoryGrid_Eliquids.jpg"
          className="card-img-top"
          alt="Product Image"
          style={{ borderRadius: '30px', height: '250px', objectFit: 'cover' }}
        />
      </div>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <h5 className="card-title fw-bold">E-Liquid</h5>
              <h5 className="fw-bold" style={{ marginLeft: "11rem" }}>$99</h5>
            </div>
            <p className="card-text">Strawberry Kiwi is deliciously sweet and tart!</p>
            <button
              type="button"
              className="btn text-white me-2"
              style={{
                background: 'linear-gradient(to top right, #EC2F4B, #009FFF)',
                border: 'none',
                padding: '5px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              >
              Buy Now <i className="bi bi-arrow-right-short"></i>
            </button>
          </div>
              </Link>
        </div>

        {/* Card 2 */}
        <div className="col-12 col-md-3 mb-4">
          <div className="card" style={{ border: '2px solid red', borderRadius: '30px' }}>
            <img
              src="https://www.vaporbeast.com/media/wysiwyg/beast/catgrid/CategoryGrid_Eliquids.jpg"
              className="card-img-top"
              alt="Product Image"
              style={{ borderRadius: '30px', height: '250px', objectFit: 'cover' }}
            />
          </div>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <h5 className="card-title fw-bold">Dragon X</h5>
              <h5 className="fw-bold" style={{ marginLeft: "11rem" }}>$99</h5>
            </div>
            <p className="card-text">This is a brief description of the product<br />brief description of the...</p>
            <button
              type="button"
              className="btn text-white me-2"
              style={{
                background: 'linear-gradient(to top right, #EC2F4B, #009FFF)',
                border: 'none',
                padding: '5px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Buy Now <i className="bi bi-arrow-right-short"></i>
            </button>
          </div>
        </div>

        {/* Card 3 */}
        <div className="col-12 col-md-3 mb-4">
          <div className="card" style={{ border: '2px solid red', borderRadius: '30px' }}>
            <img
              src="https://www.vaporbeast.com/media/wysiwyg/beast/catgrid/CategoryGrid_Eliquids.jpg"
              className="card-img-top"
              alt="Product Image"
              style={{ borderRadius: '30px', height: '250px', objectFit: 'cover' }}
            />
          </div>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <h5 className="card-title fw-bold">Little Wisher</h5>
              <h5 className="fw-bold" style={{ marginLeft: "9rem" }}>$99</h5>
            </div>
            <p className="card-text">This is a brief description of the product<br />brief description of the...</p>
            <button
              type="button"
              className="btn text-white me-2"
              style={{
                background: 'linear-gradient(to top right, #EC2F4B, #009FFF)',
                border: 'none',
                padding: '5px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Buy Now <i className="bi bi-arrow-right-short"></i>
            </button>
          </div>
        </div>

        {/* Card 4 */}
        <div className="col-12 col-md-3 mb-4">
          <div className="card" style={{ border: '2px solid red', borderRadius: '30px' }}>
            <img
              src="https://ik.imagekit.io/b6iqka2sz/image%20(1).png?updatedAt=1729757547398"
              className="card-img-top"
              alt="Product Image"
              style={{ borderRadius: '30px', height: '250px', objectFit: 'cover' }}
            />
          </div>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <h5 className="card-title fw-bold">Pencil Smok</h5>
              <h5 className="fw-bold" style={{ marginLeft: "9rem" }}>$99</h5>
            </div>
            <p className="card-text">This is a brief description of the product<br />brief description of the...</p>
            <button
              type="button"
              className="btn text-white me-2"
              style={{
                background: 'linear-gradient(to top right, #EC2F4B, #009FFF)',
                border: 'none',
                padding: '5px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Buy Now <i className="bi bi-arrow-right-short"></i>
            </button>
          </div>
        </div>
      </div>

      {/* -----------------------------second row---------------- */}
      <div className="row">
        {/* Card 1 */}
        <div className="col-12 col-md-3 mb-4">
          <div className="card" style={{ border: '2px solid red', borderRadius: '30px' }}>
            <img
              src="https://ik.imagekit.io/b6iqka2sz/image%20(1).png?updatedAt=1729757547398"
              className="card-img-top"
              alt="Product Image"
              style={{ borderRadius: '30px', height: '250px', objectFit: 'cover' }}
            />
          </div>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <h5 className="card-title fw-bold">Pencil Smok</h5>
              <h5 className="fw-bold" style={{ marginLeft: "9rem" }}>$99</h5>
            </div>
            <p className="card-text">This is a brief description of the product<br />brief description of the...</p>
            <button
              type="button"
              className="btn text-white me-2"
              style={{
                background: 'linear-gradient(to top right, #EC2F4B, #009FFF)',
                border: 'none',
                padding: '5px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Buy Now <i className="bi bi-arrow-right-short"></i>
            </button>
          </div>
        </div>

        {/* Card 2 */}
        <div className="col-12 col-md-3 mb-4">
          <div className="card" style={{ border: '2px solid red', borderRadius: '30px' }}>
            <img
              src="https://ik.imagekit.io/b6iqka2sz/image%20(1).png?updatedAt=1729757547398"
              className="card-img-top"
              alt="Product Image"
              style={{ borderRadius: '30px', height: '250px', objectFit: 'cover' }}
            />
          </div>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <h5 className="card-title fw-bold">3 Lulu Smoker</h5>
              <h5 className="fw-bold" style={{ marginLeft: "8rem" }}>$99</h5>
            </div>
            <p className="card-text">This is a brief description of the product<br />brief description of the...</p>
            <button
              type="button"
              className="btn text-white me-2"
              style={{
                background: 'linear-gradient(to top right, #EC2F4B, #009FFF)',
                border: 'none',
                padding: '5px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Buy Now <i className="bi bi-arrow-right-short"></i>
            </button>
          </div>
        </div>

        {/* Card 3 */}
        <div className="col-12 col-md-3 mb-4">
          <div className="card" style={{ border: '2px solid red', borderRadius: '30px' }}>
            <img
              src="https://ik.imagekit.io/b6iqka2sz/image%20(1).png?updatedAt=1729757547398"
              className="card-img-top"
              alt="Product Image"
              style={{ borderRadius: '30px', height: '250px', objectFit: 'cover' }}
            />
          </div>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <h5 className="card-title fw-bold">Hydra 5000</h5>
              <h5 className="fw-bold" style={{ marginLeft: "9rem" }}>$99</h5>
            </div>
            <p className="card-text">This is a brief description of the product<br />brief description of the...</p>
            <button
              type="button"
              className="btn text-white me-2"
              style={{
                background: 'linear-gradient(to top right, #EC2F4B, #009FFF)',
                border: 'none',
                padding: '5px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Buy Now <i className="bi bi-arrow-right-short"></i>
            </button>
          </div>
        </div>

        {/* Card 4 */}
        <div className="col-12 col-md-3 mb-4">
          <div className="card" style={{ border: '2px solid red', borderRadius: '30px' }}>
            <img
              src="https://www.shishavibe.com/wp-content/uploads/2023/11/Vape-Pen-Kits-vs-box-mods.webp"
              className="card-img-top"
              alt="Product Image"
              style={{ borderRadius: '30px', height: '250px', objectFit: 'cover' }}
            />
          </div>
          <div className="card-body">
            <div className="d-flex align-items-center">
              <h5 className="card-title fw-bold">Hydra 5000</h5>
              <h5 className="fw-bold" style={{ marginLeft: "9rem" }}>$99</h5>
            </div>
            <p className="card-text">This is a brief description of the product<br />brief description of the...</p>
            <button
              type="button"
              className="btn text-white me-2"
              style={{
                background: 'linear-gradient(to top right, #EC2F4B, #009FFF)',
                border: 'none',
                padding: '5px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Buy Now <i className="bi bi-arrow-right-short"></i>
            </button>
          </div>
        </div>
      </div>

    </div>
    </div>

  )
}

export default Product