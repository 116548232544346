import React  from "react";

const ViewCard =()=>{
    return (
        <div className="container py-3">
          <h3>Shopping Cart</h3>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8">
              <hr />
              <div className="cart-item py-2">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                    <div className="d-flex justify-content-between mb-3" >
                      <img
                        style={{ width: '200px', height: '200px', objectFit: 'cover', borderRadius: '5px' }}
                        src="https://www.vaporbeast.com/media/catalog/product/cache/11eaf5a98ced338c48cc93490da067aa/m/i/miav_mav_juice_head_strawberry_kiwi_100ml.jpg"
                        alt="Basic Tee"
                      />
                      <div className="mx-3">
                        <h5 className="fw-bold">Keep it 100 Synth Salts Menthol</h5>
                        <p><span className="fs-6 fw-bold">Size:</span> 100 mL </p>
                        <p><span className="fs-6 fw-bold">Nicotine Strength :</span> 3 </p>

                        <h5> <span className="fs-5 fw-bold">Price:</span>  £ 80</h5>
                        <small className="fs-5 fw-bold"
                          style={{ color: '#fff', background: "linear-gradient(to top right, #EC2F4B, #009FFF)", padding: '0.50rem 4rem', borderRadius: '0.5rem', display: 'inline-block', marginTop: '0.5rem' }}
                        >
                          In Stock
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="d-flex justify-content-between">
                      <div>
                        <select className="form-select " defaultValue="1">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                      </div>
                      <div>
                        <button type="button" className="bg-none rounded" aria-label="Close">
                        <i class="bi bi-x-lg text-danger fs-4"></i>

                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="cart-item py-2">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                    <div className="d-flex justify-content-between mb-3" >
                      <img
                        style={{ width: '200px', height: '200px', objectFit: 'cover', borderRadius: '5px' }}
                        src="https://www.vaporbeast.com/media/catalog/product/cache/991a04838e27c0fbaf408f28add1f515/i/c/icon_keep-it-100-synth-menthol-30-ml.jpg"
                        alt="Basic Tee"
                      />
                      <div className="px-3 ">
                        <h5 className="fw-bold">Keep it 100 Synth Salts Menthol</h5>
                        <p><span className="fs-6 fw-bold">Size:</span> 200 mL </p>
                        <p><span className="fs-6 fw-bold">Nicotine Strength :</span> 3 </p>

                        <h5> <span className="fs-5 fw-bold">Price:</span>  £ 800</h5>
                        <small className="fs-5 fw-bold"
                          style={{ color: '#fff', background: "linear-gradient(to top right, #EC2F4B, #009FFF)", padding: '0.50rem 4rem', borderRadius: '0.5rem', display: 'inline-block', marginTop: '0.5rem' }}
                        >
                          In Stock
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="d-flex justify-content-between">
                      <div>
                        <select className="form-select px-6 fs-5 " defaultValue="1">
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                      </div>
                      <div>
                        <button type="button" className="bg-none rounded" aria-label="Close">
                        <i class="bi bi-x-lg text-danger fs-4"></i>

                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              
            </div>
            {/* ----------------------second card----------------- */}
            
            <div className="col-12 col-sm-12 col-md-8 col-lg-4 text-dark mt-3 ">
              <div style={{ backgroundColor: '#f8f9fa', borderRadius: '0.5rem', padding: '1rem' }} className="sticky-top p-3">
                <h6 className="mb-4 fw-bold fs-4">Order Summary</h6>
                <div className="d-flex justify-content-between align-items-center">
                  <div>Subtotal</div>
                  <div><strong>£ 5000</strong></div>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <div>Delivery Charge</div>
                  <div><strong> £ 100</strong></div>
                </div>
                <hr />
                <div className="d-flex fw-bold  fs-5 justify-content-between align-items-center" >
                   <h6 className="fw-bold  fs-5">Order Total</h6>
                  <div><strong>£ 5100</strong></div>
                </div>
                <div className="d-flex justify-content-center">
  <button className="btn text-white  fw-bold w-100 fs-5 mt-4" style={{background: "linear-gradient(to top right, #EC2F4B, #009FFF)"}}>Checkout</button>
</div>

              </div>
            </div>
          </div>
        </div>
      );
    };
export default ViewCard;