import React from 'react'

function BestServices() {
  return (
    <div className='container'>
      <div className='mt-5' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <div className='row'>
          <div className='col-12 col-md-7 col-lg-7'>
        <div style={{ flex: 1,  display: 'flex', padding: '5px', paddingLeft:"10%", gap: '4px', alignItems: 'flex-start' }}>
         
        <img
  src="https://ik.imagekit.io/b6iqka2sz/assets/ali.png?updatedAt=1729789946333"
  alt="Image 2"
  className='mt-5'
  style={{
    maxWidth:"70%", // Set a specific height
    borderRadius: '8px',
    objectFit: 'cover', // Ensures the image covers the area without distortion
  }}
/>


        </div>
        </div>
        <div className='col-12 col-md-5 col-lg-5 pt-5'>
        <div style={{ flex: 1, paddingLeft: "1%" }}>
          <h6 className='text-danger'>Our Best Service</h6>
          <h1>PROVIDE THE BEST<br/>
          VAPE SERVICE</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do<br />

            eiusmod tempor incididunt ut labore et dolore magna aliqua... </p>
          <div>
           
            <button
              type="button"
              className="btn text-white me-2"
              style={{
                background: 'linear-gradient(to top right, #EC2F4B, #009FFF)',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              Learn more <i className="bi bi-arrow-right-short"></i>
            </button>

          </div>
        </div>
        </div>
        </div>
      </div>

    </div>
  )
}

export default BestServices