// components/Navbar.js
import React, {useState} from 'react';
import styles from "./css/Navbar.module.css";
import { FaFacebookF, FaTwitter, FaInstagram, FaBars,FaYoutube, FaTiktok, FaClock, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';
import Container from '../helper/Container';
import Env from '../Env';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle menu visibility
  const handleMenuToggle = () => {
    setIsMenuOpen(prevState => !prevState);
  };
  return (
    <Container >
      <nav className={`${styles.customNavbar}`} >
        <div className={`container-fluid d-flex justify-content-between align-items-center ${styles.combinedRow}`}>
          {/* Logo Section */}
          <a className={`navbar-brand ${styles.logo}`} href="/">
            <img src={Env.logo} alt={Env.businessName} style={{ marginRight: '10px' }} />
            
          </a>
          {/* Info Container */}

          <div className={`${styles.infoContainer}`}>
            <div className={`d-flex align-items-center ${styles.topInfoLeft}`}>
              <FaClock className={styles.icon} />
              <span className={styles.infoText}>{Env.Time}</span>
              <span className={styles.separator}>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <FaEnvelope className={styles.emailIcon} />
              <a href="mailto:info@vapenation.com" className={styles.infoText}>{Env.Email}</a>
            </div>
            <div className={`d-flex align-items-center ${styles.socialIconsRight}`}>
              <FaFacebookF className={styles.socialIcon} />
              <FaTwitter className={styles.socialIcon} />
              <FaInstagram className={styles.socialIcon} />
              <FaYoutube className={styles.socialIcon} />
              <FaTiktok className={styles.socialIcon} />
            </div>
          </div>




        </div>

        <a className={`navbar-brand ${styles.logolesswidth990}`} href="/">
  <img src={Env.logo} alt={Env.businessName} />
</a>



<div className={`d-flex justify-content-between align-items-center ${styles.navContainer}`}>
  <a className={`navbar-brand ${styles.logolesswidth780}`} href="/">
    <img src={Env.logo} alt={Env.businessName} />
  </a>

  <button className={styles.menuButton}>
    <FaBars />
  </button>
</div>


{/* Navigation Links - Hidden by default on mobile */}
<div className={`${styles.navLinksContainer} ${isMenuOpen ? styles.menuOpen : ''}`}>
  <ul className={`d-flex ${styles.navLinks}`}>
    <li><a href="#" className={styles.navLink}>Home</a></li>
    <li><a href="#" className={styles.navLink}>Products</a></li>
    <li><a href="#" className={styles.navLink}>Services</a></li>
    <li><a href="#" className={styles.navLink}>Pages</a></li>
    <li><a href="#" className={styles.navLink}>Contact Us</a></li>
    <button className={styles.phoneButton}><FaPhoneAlt />{Env.PhNum}</button>
  </ul>
</div>



  





      </nav>
      
    </Container>
  );
}

export default Navbar;
