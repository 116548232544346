import React from "react";
const Checkout = () => {
  return (
    <>
      <div className="container py-3">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-8 col-lg-8">
            <div class=" " style={{ borderBottom: '3px solid white' }}>

              <h2>Checkout form</h2>
            </div>
            <div class=" order-md-1 mt-3">
              <h4 class="mb-3"> <i class="bi bi-check-circle fs-1"></i> &nbsp; Shipping address</h4>
              <form class="needs-validation" novalidate>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="firstName " className="fw-bold fs-6">First name "</label>
                    <input type="text" class="form-control" placeholder="" required />

                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="lastName" className="fw-bold fs-6">Last name *</label>
                    <input type="text" class="form-control" id="lastName" placeholder="" required />

                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="firstName " className="fw-bold fs-6">Street Address Line *</label>
                    <input type="text" class="form-control" placeholder="" required />

                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="lastName" className="fw-bold fs-6"> Street Address Line 2*</label>
                    <input type="text" class="form-control" id="lastName" placeholder="" required />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="country" className="fw-bold fs-6">Country</label>
                    <select class="form-select" aria-label="Default select example">
                      <option selected>United States</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="country" className="fw-bold fs-6"> State/province *</label>
                    <select class="form-select" aria-label="Default select example">
                      <option selected>Please select a region, state or province.</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label for="zip" className="fw-bold fs-6">City *</label>
                    <input type="text" class="form-control" id="zip" placeholder=" City " required />
                    <div class="invalid-feedback">
                      Zip code required.
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="zip" className="fw-bold fs-6">Zip/Postal Code *</label>
                    <input type="text" class="form-control" id="zip" placeholder="Zip/Postal Code " required />
                    <div class="invalid-feedback">
                      Zip code required.
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="zip" className="fw-bold fs-6">Phone Number *</label>
                    <input type="text" class="form-control" id="zip" placeholder="Phone Number" required />
                    <div class="invalid-feedback">
                      Zip code required.
                    </div>
                  </div>
                </div>
                <hr class="mb-4" />
                <h4 class="mb-3"> <i class="bi bi-check-circle fs-1"></i> &nbsp; Shipping and Handling</h4>
                <p className="p-3 rounded" style={{backgroundColor:"#fff3cd",color:"#000000"}}>Please choose your shipping address to see shipping rates.</p>
                <hr class="mb-4" />
                <h4 class="mb-3"> <i class="bi bi-check-circle fs-1"></i> &nbsp; Payment method</h4>
                <div className="border p-5 rounded">
               <h4 className="text-danger "> <i class="bi bi-check-circle fs-1"></i> &nbsp;Card Information</h4> 
               <img src="https://ik.imagekit.io/b6iqka2sz/file-LntcohjLaE-removebg-preview.png?updatedAt=1730976683851" class="img-fluid rounded p-1 rounded" style={{width:'40%'}} alt="Payment Methods"/>

                <div class="row">
                  <div class="col-md-12 mb-3">
                    <label for="cc-name" className="fw-bold fs-6">Card Number *</label>
                    <input type="text" class="form-control" id="cc-name" placeholder="5590490206283580" required />
                    <small class="text-muted">Full name as displayed on card</small>
                    <div class="invalid-feedback">
                      Name on card is required
                    </div>
                  </div>
                </div>
               
                <div class="row">
                  <div class="col-md-6 mb-3">
                    <label for="cc-expiration" className="fw-bold fs-6">Expiration Date *</label>
                    <input type="text" class="form-control" id="cc-expiration" placeholder="10-October" required />
                    <div class="invalid-feedback">
                      Expiration date required
                    </div>
                  </div>
                  <div class="col-md-6 mb-3 mt-4">
                    <input type="text" class="form-control" id="cc-cvv" placeholder="2017" required />
                    <div class="invalid-feedback">
                      Security code required
                    </div>
                  </div>
                </div>
                <div class="row">
                 
                 <div class="col-md-12 mb-3">
                   <label for="cc-number" className="fw-bold fs-6 ">CVV *</label>
                   <input type="text" class="form-control" id="cc-number" placeholder="978" required />
                   <div class="invalid-feedback">
                     Credit card number is required
                   </div>
                 </div>
               </div>
                <div class="d-flex justify-content-end mt-3 ">
  <button class="btn text-white fw-bolder btn-lg " type="submit" style={{ background: "linear-gradient(to top right, #EC2F4B, #009FFF)" }}>Continue to checkout</button>
</div>
</div>
              </form>
            </div>
          </div>
          {/* ----------------------second card----------------- */}

          <div className="col-12 col-sm-12 col-md-4 col-lg-4 text-dark mt-3 ">
            <div style={{ backgroundColor: '#f8f8f8', borderRadius: '0.5rem', padding: '1rem' }} className="sticky-top p-3">
              <h6 className="mb-4 fw-bold fs-3"> <i class="bi bi-check-circle fs-3"></i> &nbsp; Order Review</h6>
              <div className="d-flex justify-content-between align-items-center">
              <div>Subtotal</div>
              <div>£ 5000</div>
              </div>
              <div className="d-flex justify-content-between align-items-center py-3">
                <div>Shipping</div>
                <div>Not yet calculated</div>
              </div>
              <div className="d-flex justify-content-between align-items-center ">
                <div>Discount</div>
                <div> £ 8.00</div>
              </div>
              <hr/>
              <div className="d-flex fw-bold  fs-5 justify-content-between align-items-center" >
                <h6 className="fw-bold  fs-5">Order Total</h6>
                <div><strong>£ 5100</strong></div>
                
              </div>
              
              <hr/> 
              <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"   />
  <label class="form-check-label" for="flexCheckDefault">
  I certify that I am of legal age in my state to purchase electronic cigarette products.
  </label>
</div>



<div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
  <label class="form-check-label" for="flexCheckChecked">
  I have verified my order and shipping method.
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"  />
  <label class="form-check-label" for="flexCheckDefault">
  I Acknowledge that I have read and understand this Battery Safety Notice.
  </label>
</div>
<div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"  />
  <label class="form-check-label" for="flexCheckDefault">
  I Acknowledge this Disclaimer
  </label>
</div>
              <div className="d-flex justify-content-center">
                <button className="btn text-white  fw-bold w-100  mt-4" style={{ background: "linear-gradient(to top right, #EC2F4B, #009FFF)" }}>VERIFY AGE & PLACE ORDER</button>
              </div>
          
<div className="text-center pt-2">
  <p className="text-danger fw-bolder">
    <i className="bi bi-lock fs-5"></i> &nbsp;100% Secure Payment
  </p>
  <img src="https://www.vaporbeast.com/media/wysiwyg/vaporbeast/authorizenet.png"/> &nbsp;&nbsp;&nbsp;
  <img src="https://www.vaporbeast.com/media/wysiwyg/vaporbeast/comodo_secure_seal.png"/>
</div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Checkout;